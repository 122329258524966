import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import moment from 'moment';
import historyStoreModule from '../historyStoreModule';

export default function useHistoryList() {
  const APARTMENT_STORE_MODULE_NAME = "history";

  // Register module
  if (!store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
    store.registerModule(APARTMENT_STORE_MODULE_NAME, historyStoreModule);
  }

  // UnRegister on leave
  // onUnmounted(() => {
  //   if (store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
  //     store.unregisterModule(APARTMENT_STORE_MODULE_NAME);
  //   }
  // });
  // Use toast
  const toastification = toast();

  const blankItem = {
    name: '',
    province: null,
    district: null,
    ward: null,
    address: '',
    active: true,
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  // Table Handlers
  const columns = [
    {
      label: 'Thao tác',
      field: 'action',
      width: '100px',
      tdClass: 'text-center',
      sortable: false,
    },
    {
      label: 'Bác sĩ',
      field: 'doctor',
    },
    {
      label: 'Bệnh nhân',
      field: 'patient',
    },
    {
      label: 'Thời gian khám',
      field: 'duration',
    },
    {
      label: 'Trạng thái',
      field: 'status',
    },

  ];
  const analytics = ref();
  const rows = ref([]);
  // filter
  const patient = ref(null);
  const doctor = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    start: 0,
    length: 50,
    status: "-1",
    draw: "1",
    specialist: "-1",
    subSpecialist: "-1",
  });

  //   API Call

  const fetchMeetings = () => {
    store
      .dispatch('history/fetchMeetings', serverParams.value)
      .then(response => {
        const { data, recordsTotal } = response.data;
        totalRecords.value = recordsTotal;
        rows.value = data;
        isLoading.value = false;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };
  const fetchData = () => {
    isLoading.value = true;
    fetchMeetings();
  };
  const importData = file => {
    const formData = new FormData();
    formData.append('file', file);
    store
      .dispatch('apartment/importApartments', formData)
      .then(() => {
        fetchData();
        toastification.showToastCreateSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('apartment/exportApartments', serverParams.value)
      .then(response => {
        console.log(response.data);
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteApartments = apartments => {
    store
      .dispatch('apartment/deleteApartments', {
        ids: apartments.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const onEditItem = val => {
    item.value = val;
  };
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ start: (params.currentPage - 1) * serverParams.value.length });
  };

  const onPerPageChange = params => {
    updateParams({ length: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ search: { value: val } });
  });
  watch(doctor, val => {
    updateParams({ doctorId: val ? val._id : "-1" });
  });

  // UI
  const resolveStatusVariant = val => {
    if (val === 'end') return { variant: 'primary', title: "Đã khám", icon: 'SmileIcon' };
    if (val === 'created') return { variant: 'secondary', title: "Chờ khám", icon: 'SmileIcon' };
    if (val === 'live') return { variant: 'success', title: "Đang khám", icon: 'SmileIcon' };
    return { variant: 'warning', title: "Đã hủy", icon: 'MehIcon' };
  };

  const resolveFamilyDoctorVariant = val => {
    if (val) return { variant: 'primary', title: "Có", icon: 'CheckIcon' };
    return { variant: 'danger', title: "Không", icon: 'MinusIcon' };
  };

  const resolveTimeLength = val => {
    const minutes = moment(val.end_at).diff(moment(val.created_at), 'minutes');
    const hours = parseInt(minutes / 60, 10);
    const remainMinutes = minutes - hours * 60;

    const hourString = hours > 0 ? `${hours} giờ ${remainMinutes > 0 ? `${remainMinutes} phút` : ''}` : `${remainMinutes} phút`;
    const durationString = `${moment(val.created_at).format('DD/MM/YYYY HH:mm')} - ${moment(val.end_at).format('DD/MM/YYYY HH:mm')}`;
    return { durationString, hourString: val.status === 'cancel' ? '' : hourString };
  };

  return {
    analytics,
    item,
    columns,
    rows,
    doctor,
    patient,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    fetchData,
    deleteApartments,
    onEditItem,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    importData,
    exportData,
    resolveStatusVariant,
    resolveFamilyDoctorVariant,
    resolveTimeLength,
  };
}
